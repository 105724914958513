import { faCircle } from "@fortawesome/free-solid-svg-icons/faCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  pathStop: {
    display: "flex",
    gap: "0.6rem",
    alignItems: "start",
    height: "2.3rem",
    outline: "none",
    border: "none",
    padding: 0,

    [ScreenSizes.xxlAndAbove]: {
      gap: "1rem",
    },

    ":hover": {
      cursor: "pointer",
    },
  },
  dot: {
    fontSize: "0.375rem",
    color: colours.greyDot,
    marginTop: 6,
  },
  label: {
    color: colours.black,
    ...gStyles.avalonBold,
    fontSize: "0.875rem",
    margin: 0,

    [ScreenSizes.xxlAndAbove]: {
      fontSize: "1rem",
    },
  },
  active: {
    color: colours.primary,
  },
};

export default function NavItem(props) {
  const { styles } = useStyles(baseStyles, props);

  const { active, label, onClick } = props;

  return (
    <button className={css(styles.pathStop)} onClick={onClick}>
      <span className={css(styles.dot, active && styles.active)}>
        <FontAwesomeIcon icon={faCircle} dataid={label} />
      </span>
      <p className={css(styles.label, active && styles.active)}>{label}</p>
    </button>
  );
}

NavItem.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
