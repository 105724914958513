import colours from "styles/colours";

export const getPoliticalSkew = ({
  politicalSkew,
  politicalLeanDegree = 10,
}) => {
  switch (politicalSkew) {
    case "Left": {
      if (politicalLeanDegree === 1) {
        return {
          title: "Low Left",
          color: colours.blue,
          icon1: colours.blue,
          icon2: colours.lightBlue,
          icon3: colours.lightBlue,
          backgroundColor: colours.lightBlue,
        };
      }
      if (politicalLeanDegree === 2) {
        return {
          title: "Medium Left",
          color: colours.blue,
          icon1: colours.blue,
          icon2: colours.blue,
          icon3: colours.lightBlue,
          backgroundColor: colours.midiumBlue,
        };
      }
      if (politicalLeanDegree === 3) {
        return {
          title: "High Left",
          color: colours.blue,
          icon1: colours.blue,
          icon2: colours.blue,
          icon3: colours.blue,
          backgroundColor: colours.highBlue,
        };
      }
      return {
        title: "Low Left",
        color: colours.blue,
        icon1: colours.blue,
        icon2: colours.lightBlue,
        icon3: colours.lightBlue,
        backgroundColor: colours.lightBlue,
      };
    }
    case "Center":
      return {
        title: "Neutral/Mixed",
        color: colours.politicalNeutral,
        icon1: colours.lightBlue,
        icon2: colours.neutralBG,
        icon3: colours.lightRed,
        backgroundColor: colours.neutralBG,
      };
    case "Right": {
      if (politicalLeanDegree === 1) {
        return {
          title: "Low Right",
          color: colours.politicalRed,
          icon1: colours.politicalRed,
          icon2: colours.lightRed,
          icon3: colours.lightRed,
          backgroundColor: colours.lightRed,
        };
      }
      if (politicalLeanDegree === 2) {
        return {
          title: "Medium Right",
          color: colours.politicalRed,
          icon1: colours.politicalRed,
          icon2: colours.politicalRed,
          icon3: colours.lightRed,
          backgroundColor: colours.mediumRed,
        };
      }
      if (politicalLeanDegree === 3) {
        return {
          title: "High Right",
          color: colours.politicalRed,
          icon1: colours.politicalRed,
          icon2: colours.politicalRed,
          icon3: colours.politicalRed,
          backgroundColor: colours.highRed,
        };
      }
      return {
        title: "Low Right",
        color: colours.politicalRed,
        icon1: colours.politicalRed,
        icon2: colours.lightRed,
        icon3: colours.lightRed,
        backgroundColor: colours.lightRed,
      };
    }
    default:
      return {
        title: "",
        color: "",
        icon1: "",
        icon2: "",
        icon3: "",
        backgroundColor: "",
      };
  }
};
